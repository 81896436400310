/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .row-header {
        display: none;
    }
    /*
    #header {
        left: 0px;
        height: 2em;
    }

    .row-header {
        height: 2em;
        max-width: 480px;
        left: 0;
        background: #f2f2f2;
    } */
}
  
/* Media Query for low resolution  Tablets, Ipads */
/* @media (min-width: 481px) and (max-width: 767px) {
    body {
        background-color: yellow;
    }
} */
  
/* Media Query for Tablets Ipads portrait mode */
/* @media (min-width: 768px) and (max-width: 1024px){
    body {
        background-color: blue;
    }
} */
  
/* Media Query for Laptops and Desktops */
// @media (min-width: 1025px) and (max-width: 1280px) {
@media (min-width: 1025px) {
    #header {
        left: 0px;
        height: 2.5em;
    }
    
    .row-header {
        position: absolute;
        height: 2.5em;
        width: 101%;
        left: 0;
        background: #f2f2f2;
    }
}
  
/* Media Query for Large screens */
/* @media (min-width: 1281px) {
    body {
        background-color: white;
    }
} */