.text-white {
    color: #ffffff !important;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .section-banner-img {
        display: none;
    }

    .section {
        position: relative;

        &-responsive-banner-img {
            padding-top: 200px;
            padding-bottom: 175px;
            width: 100%;
            background: url("../assets/banner.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }

    .home-title {
        font-size: 14px;
        line-height: 28px;
        padding-left: 40px;
        padding-right: 40px;
        font-family: 'Titillium Web', sans-serif;
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    .section-responsive-banner-img {
        display: none;
    }

    .section {
        position: relative;
    
        &-banner-img {
            padding-bottom: 230px;
            padding-top: 300px;
            width: 100%;
            background: url("../assets/banner.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    
    .home-title {
        font-size: 36px;
        line-height: 64px;
        font-family: 'Titillium Web', sans-serif;
        /* letter-spacing: 3px; */
    }
}
