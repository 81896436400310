/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .block-programmes {
        &-banner {
            background: black;
            opacity: 0.6;
            width: 100%;
            margin: auto;
            position: absolute;
            height: 100%;
            top: 0;
        }

        &-title {
            color: white;
            font-family: 'Titillium Web', sans-serif;
            font-size: 26px;
            font-weight: 600;
            justify-content: center;
            padding-bottom: 30px;
            padding-top: 10px;
            position: relative;
        }
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    .block-programmes {
        &-banner {
            background: black;
            opacity: 0.6;
            width: 75%;
            margin: auto;
            position: absolute;
            height: 100%;
            top: 0;
            left: 13%;
        }

        &-title {
            color: white;
            font-family: 'Titillium Web', sans-serif;
            font-size: 42px;
            font-weight: 600;
            justify-content: center;
            padding-bottom: 25px;
            padding-top: 25px;
            position: relative;
        }

        &-item {
            color: white;
            font-family: 'Titillium Web', sans-serif;
            font-size: 24px;
            font-weight: 500;
            justify-content: center;
            position: relative;
            padding-bottom: 25px;

            & a {
                font-size: 18px;
                justify-content: center;
            }
        }
    }
}
