/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    .vertical-bar {
        height: 50px !important;
        width: 2px;
        background: white;
    
        margin-left: 5px;
        margin-right: 5px;
    }
}
