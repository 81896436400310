 /* Media Query for Mobile Devices */
@media (max-width: 480px) {
    #social-media {
        text-align: right;
        top: 1%; /* TODO */
        position: relative;
    }
    
    .social-media-logo {
        height: 20px;
        width: 20px;
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    #social-media {
        text-align: right;
        top: 17%; /* TODO */
        position: relative;
    }
    
    .social-media {
        &-logo {
            height: 25px;
            width: 25px;
        }

        &-row {
            width: 120px;
            margin-left: 20px;
        }
    }
}