/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .block-school {
        &-banner { // Not used
            background: black;
            opacity: 0.6;
            width: 100%;
            margin: auto;
            position: absolute;
            height: 100%;
            top: 0;
        }

        &-title { // Not used
            color: white;
            font-family: 'Titillium Web', sans-serif;
            font-size: 26px;
            font-weight: 600;
            justify-content: center;
            padding-bottom: 10px;
            padding-top: 10px;
            position: relative;
        }

        &-content {
            color: white;
            font-family: 'Titillium Web', sans-serif;
            font-size: 12px;
            font-weight: 500;
            justify-content: center;
            position: relative;
            padding-top: 5px;
            padding-bottom: 5px;
            width: 90%;
            margin: auto;
        }
    }
}


/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    .block-school {
        &-banner { // Not used
            background: black;
            opacity: 0.6;
            width: 75%;
            margin: auto;
            position: absolute;
            height: 100%;
            top: 0;
            left: 13%;
        }

        &-title { // Not used
            color: white;
            font-family: 'Titillium Web', sans-serif;
            font-size: 42px;
            font-weight: 600;
            justify-content: center;
            padding-bottom: 25px;
            padding-top: 25px;
            position: relative;
        }

        &-content {
            color: white;
            font-family: 'Titillium Web', sans-serif;
            font-size: 20px;
            font-weight: 600;
            justify-content: center;
            position: relative;
            padding-top: 50px;
            padding-bottom: 50px;
            width: 80%;
            margin: auto;
        }
    }
}