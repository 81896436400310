/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .logo {
        &-img {
            height: 100px;
            width: 100px;
        }
    
        &-link {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 70%;
        }
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    .logo {
        &-img {
            height: 145px;
            width: 145px;
        }
    
        &-link {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 40%;
        }
    }
}
