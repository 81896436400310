/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .section-mission {
        display: none;
    }

    .section {
        position: relative;
    
        &-responsive-mission {
            height: 250px;
            width: 100%;
            background: #66696D;
            opacity: 0.92;
        }
    }
    
    .row {
        &-mission {
            &-title {
                padding-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                letter-spacing: 4px;
                font-weight: 900;
                font-size: 24px;
                color: white;
                font-family: 'Titillium Web', sans-serif;
            }

            &-logo {
                background: white;
                border-radius: 50%;
                height: 80px;
                width: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                margin-left: 10%;
                margin-top: 25px;
            }

            &-name {
                padding-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                text-decoration: none;
                text-decoration-color: rgb(25, 24, 24);
                color: rgb(25, 24, 24);
    
                font-size: 16px;
                font-weight: 500;
                font-family: 'Titillium Web', sans-serif;
            }
        }
    }

    #container-mission {
        height: 300px;
        padding: 0;
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    .section-responsive-mission {
        display: none;
    }

    .section {
        position: relative;
    
        &-mission {
            height: 500px;
            width: 100%;
            background: #66696D;
            opacity: 0.92;
        }
    }
    
    .row {
        &-mission {
            &-title {
                padding-top: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                letter-spacing: 4px;
                font-weight: 900;
                font-size: 56px;
                color: white;
                font-family: 'Titillium Web', sans-serif;
            }
    
            &-logo {
                background: white;
                border-radius: 50%;
                height: 130px;
                width: 130px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                margin-left: 31%;
                margin-top: 75px;
            }
    
            &-name {
                padding-top: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                text-decoration: none;
                text-decoration-color: rgb(25, 24, 24);
                color: rgb(25, 24, 24);
    
                font-size: 24px;
                font-weight: 500;
                font-family: 'Titillium Web', sans-serif;
            }
        }
    }
    
    #container-mission {
        /* border-left: black dashed;
        border-right: black dashed; */
        height: 700px;
    }
}
