/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .mission {
        height: 200px;
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    .mission {
        height: 400px;
    }
}
