/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .section-footer {
        display: none;
    }

    .section-responsive-footer {
        background: #66696D;
        padding-bottom: 30px;
    }

    .row {
        &-copyright {
            padding-top: 15px;
            padding-left: 85px;
            font-family: 'Titillium Web', sans-serif; 
        }

        &-footer {

            &-content {
                margin-top: 10%;
                margin-left: 0px;
                margin-right: 0px;
            }

            &-social {
                padding: 0;
                margin: 0;
                height: 40px;
                background: #f2f2f2;
            }
        }
    
        &-social {
            width: 120px;
            height: 40px;
            margin-left: 135px;
            padding-top: 7px;
        }
    }

    .footer {
        &-logo {
            &-img {
                height: 70px;
                width: 70px;
            }
        }

        &-social-img {
            height: 20px;
            width: 20px;
        }

        &-col-content {
            margin: auto;
            width: 75%;

            h1 {
                font-size: 16px;
                font-weight: 600;
                font-family: 'Titillium Web', sans-serif;
                color: black;
                text-transform: uppercase;
                letter-spacing: 2px;

                &:hover {
                    color: #f2f2f2;
                }
            }
    
            ul {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.3rem;
            
                padding-left: 15px;
                font-family: 'Titillium Web', sans-serif;
            }
    
            li, a {
                list-style: none;
                color: black;
                font-size: 14px;
                cursor: pointer;
                text-decoration: none;
    
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    .section-responsive-footer {
        display: none;
    }

    .section-footer {
        background: #66696D;
        height: 400px;
    }
    
    .row {
        &-content, &-footer {
            height: 80%;
        }
    
        &-copyright {
            padding-top: 15px;
            padding-left: 15px;
            font-family: 'Titillium Web', sans-serif; 
        }
    
        &-social {
            padding-top: 10px;
            width: 150px;
        }
    }
    
    .footer {
        &-logo {
            height: 130px;
            width: 130px;
            background: white;
            border-radius: 50%;
    
            display: flex;
            justify-content: center;
            align-items: center;
    
            margin-top: 20%;
            margin-left: 35%;
    
            &-img {
                height: 110px;
                width: 110px;
            }
        }
    
        &-stepper {
            height: 130px;
            width: 130px;
            margin-top: 20%;
            margin-left: 35%;
    
            &-img {
                height: 60px;
                width: 60px;
            }
        }
    
        &-content {
            height: 100%;
        
            padding-top: 40px;
            padding-left: 10px;
        }
    
        &-social-img {
            height: 25px;
            width: 25px;
        }
        
        &-col-content {
            h1 {
                font-size: 20px;
                font-weight: 600;
                font-family: 'Titillium Web', sans-serif;
                color: black;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
    
            ul {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.3rem;
            
                padding-left: 0;
                font-family: 'Titillium Web', sans-serif;
            }
    
            li, a {
                list-style: none;
                color: black;
                cursor: pointer;
                text-decoration: none;
    
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
