/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    .horizontal-bar {
        height: 2px;
        width: 50px;
        background: white;
    
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
