/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    #programmes {
        display: none;
    }

    .section-responsive-programmes {
        height: 400px;
        background-color: white;
    }

    .block-programmes {
        background: whitesmoke;
        height: 300px;
        width: 350px;
        margin: auto;
        overflow: auto;
        position: absolute;
        left: 9%;
        top: 12%;
        border-radius: 4%;
        border: solid #49B6CE 2px;
        box-shadow: 0 0 2em #49B6CE;
    }

    .row-programmes {
        display: flex;
        justify-content: space-around;
        text-decoration: none;
        align-items: center;
        color: white;
        font-size: 8px;
        font-weight: bold;
        font-family: 'Titillium Web', sans-serif;
        width: 300px;
        height: 30px;
        margin-top: 7px;
        margin-left: 23px;
        border-radius: 10px;
        background-color: #49B6CE;
        border: solid whitesmoke 2px;
    
        &:hover {
            border: solid #49B6CE 2px;
            background-color: whitesmoke;
            color: #49B6CE;
        }
    
        &-title {
            padding-top: 15px;
            padding-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Titillium Web', sans-serif;
            font-size: 13px;
            font-weight: bold;
            color: #49B6CE;
        }
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    #programmes-responsive {
        display: none;
    }

    .section-programmes {
        height: 700px;
        background-color: white;
    }
    
    .block-programmes {
        /* background-color: #49B6CE; */
        /* background: linear-gradient(to bottom, rgba(73, 182, 206, 70%) 0%, rgba(73, 182, 206, 50%)); */
        background: whitesmoke;
        height: 550px;
        width: 900px;
        margin: auto;
        overflow: auto;
        position: absolute;
        left: 20%;
        top: 10%;
        border-radius: 3%;
        border: solid #49B6CE 2px;
        box-shadow: 0 0 2em #49B6CE;
    }
    
    .row-programmes {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: white;
        font-size: 20px;
        font-weight: bold;
        font-family: 'Titillium Web', sans-serif;
        width: 750px;
        height: 45px;
        margin-top: 20px;
        margin-left: 60px;
        border-radius: 10px;
        /* background-color: linear-gradient(to bottom, rgba(73, 182, 206, 70%) 0%, rgba(73, 182, 206, 50%)); */
        background-color: #49B6CE;
        border: solid whitesmoke 2px;
    
        &:hover {
            border: solid #49B6CE 2px;
            background-color: whitesmoke;
            color: #49B6CE;
        }
    
        &-title {
            padding-top: 25px;
            padding-bottom: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Titillium Web', sans-serif;
            font-size: 26px;
            font-weight: bold;
            color: #49B6CE;
        }
    }
}

