 /* Media Query for Mobile Devices */
 @media (max-width: 480px) {
    #language {
        position: relative;
        top: 40%;
    
        padding-left: 0;
    }
    
    .language {
        &-img {
            padding-left: 0;
            padding-right: 0;
    
            &-flag {
                height: 25px;
                width: 25px;
                border-radius: 30%;
                display: block;
                position: relative;
                margin-right: 10px;
            }
        }
    
        &-list {
            padding-left: 0;
        }
    
        &-item {
            font-size: small;
            text-decoration: unset !important;
            color: rgb(13, 110, 253);
            padding-right: 10px;
    
            &-selected {
                font-size: small;
                color: rgb(13, 110, 253);
                text-decoration: underline !important;
                padding-right: 10px;
            }
        }
    }
 }

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    #language {
        position: relative;
        top: 15%;
    
        padding-left: 0;
    }
    
    .language {
        &-img {
            padding-left: 0;
            padding-right: 0;
    
            &-flag {
                height: 30px;
                width: 30px;
                border-radius: 30%;
                display: block;
                position: relative;
                margin-right: 10px;
            }
        }
    
        &-list {
            padding-left: 0;
        }
    
        &-item {
            font-size: small;
            text-decoration: unset !important;
            color: rgb(13, 110, 253);
            padding-right: 10px;
    
            &-selected {
                font-size: small;
                color: rgb(13, 110, 253);
                text-decoration: underline !important;
                padding-right: 10px;
            }
        }
    }
}
