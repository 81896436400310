/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    #menu {
        display: none;

        &-responsive {
            height: auto; // TODO
        }
    }

    .menu {
        &-contact {
            &-btn {
                background: #66696D;
                width: 120px;
                height: 40px;
                border: solid #66696D 2px;
                border-radius: 10px;
                font-family: 'Titillium Web', sans-serif;

                & a { // TODO
                    text-decoration: none;
                    color: inherit;
                }
        
                &:hover {
                    background-color: white;
                    color: #66696D;
                }
            }
    
            &-container {
                padding: 0;
            }
    
            &-icon {
                margin-right: 5px;
            }
        }

        &-icon {
            height: 45px;
            width: 45px;
            margin-top: 35%;
        }

        &-responsive {
            &-button-list {
                margin-top: 15%;
                margin-left: 45px;
                margin-right: 45px;
            }

            &-open {
                height: 50vh;
                transform: translateX(0);
            }

            &-close {
                height: 0;
                // z-index: 200; 
                transform: translateX(-100%);
                transition: transform 0.3s ease-out;
            }
        }

        &-title {
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 10px;
            font-family: 'Titillium Web', sans-serif;
            font-weight: 900;
            font-size: 20px;

            &-items {
                & ul {
                    padding: 0;
                }

                & a {
                    margin-left: 40px;

                    text-decoration: none;
                    text-decoration-color: rgb(25, 24, 24);
                    color: rgb(25, 24, 24);
            
                    font-size: 16px;
                    font-weight: lighter;
                    font-family: 'Titillium Web', sans-serif;
            
                    &:hover {
                        text-decoration-color: #49B6CE;
                        color: #49B6CE;
                    }
                }
            }
        }
    }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
    #menu {
        height: 155px;

        &-responsive {
            display: none;
        }
    }
    
    .menu {
        &-item {
            text-align: center;
            line-height: 9;
            font-family: 'Open Sans', sans-serif;
        
            &-container {
                width: 75%;
                height: 155px;
                margin: auto;
            }

            &-link {
                text-decoration: none;
                text-decoration-color: rgb(25, 24, 24);
                color: rgb(25, 24, 24);
        
                font-size: 18px;
                font-weight: 600;
                font-family: 'Titillium Web', sans-serif;

                width: auto;
                margin: auto;
        
                &:hover {
                    text-decoration-color: #49B6CE;
                    color: #49B6CE;
                }
            } 
        }

        &-subitem {
            position: relative;
            z-index: 2;
            background: white;
            height: 100px;
            margin: auto;

            &-link {
                text-decoration: none;
                text-decoration-color: rgb(25, 24, 24);
                color: rgb(25, 24, 24);
        
                font-size: 18px;
                font-weight: 600;
                font-family: 'Titillium Web', sans-serif;
                line-height: 100px;
                
                width: auto;
                margin: auto;

                &:hover {
                    text-decoration-color: #49B6CE;
                    color: #49B6CE;
                }
            }

            &:last-child {
                border-radius: 0 0 5px 5px;
            }
        }
    
        &-divider {
            width: 10px;
        }
    
        &-button {
            &-list {
                margin-top: 60px;
                height: 36.5px !important;
            }
        
            &-contact {
                background-color: #AF332E;
                color: aliceblue;
            }
        }
    
        &-contact {
            width: 300px;
        
            &-btn {
                background: #66696D;
                width: 120px;
                height: 40px;
                border: solid #66696D 2px;
                border-radius: 10px;
                font-family: 'Titillium Web', sans-serif;

                & a { // TODO
                    text-decoration: none;
                    color: inherit;
                }
        
                &:hover {
                    background-color: white;
                    color: #66696D;
                }
            }
    
            &-container {
                padding: 0;
            }
    
            &-icon {
                margin-right: 5px;
            }
        }
    }    
}
