.text-white {
  color: #ffffff !important;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .section-banner-img {
    display: none;
  }
  .section {
    position: relative;
  }
  .section-responsive-banner-img {
    padding-top: 200px;
    padding-bottom: 175px;
    width: 100%;
    background: url("../assets/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .home-title {
    font-size: 14px;
    line-height: 28px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: "Titillium Web", sans-serif;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  .section-responsive-banner-img {
    display: none;
  }
  .section {
    position: relative;
  }
  .section-banner-img {
    padding-bottom: 230px;
    padding-top: 300px;
    width: 100%;
    background: url("../assets/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .home-title {
    font-size: 36px;
    line-height: 64px;
    font-family: "Titillium Web", sans-serif;
    /* letter-spacing: 3px; */
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  .horizontal-bar {
    height: 2px;
    width: 50px;
    background: white;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  .vertical-bar {
    height: 50px !important;
    width: 2px;
    background: white;
    margin-left: 5px;
    margin-right: 5px;
  }
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .section-footer {
    display: none;
  }
  .section-responsive-footer {
    background: #66696D;
    padding-bottom: 30px;
  }
  .row-copyright {
    padding-top: 15px;
    padding-left: 85px;
    font-family: "Titillium Web", sans-serif;
  }
  .row-footer-content {
    margin-top: 10%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .row-footer-social {
    padding: 0;
    margin: 0;
    height: 40px;
    background: #f2f2f2;
  }
  .row-social {
    width: 120px;
    height: 40px;
    margin-left: 135px;
    padding-top: 7px;
  }
  .footer-logo-img {
    height: 70px;
    width: 70px;
  }
  .footer-social-img {
    height: 20px;
    width: 20px;
  }
  .footer-col-content {
    margin: auto;
    width: 75%;
  }
  .footer-col-content h1 {
    font-size: 16px;
    font-weight: 600;
    font-family: "Titillium Web", sans-serif;
    color: black;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .footer-col-content h1:hover {
    color: #f2f2f2;
  }
  .footer-col-content ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
    padding-left: 15px;
    font-family: "Titillium Web", sans-serif;
  }
  .footer-col-content li, .footer-col-content a {
    list-style: none;
    color: black;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
  }
  .footer-col-content li:hover, .footer-col-content a:hover {
    text-decoration: underline;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  .section-responsive-footer {
    display: none;
  }
  .section-footer {
    background: #66696D;
    height: 400px;
  }
  .row-content, .row-footer {
    height: 80%;
  }
  .row-copyright {
    padding-top: 15px;
    padding-left: 15px;
    font-family: "Titillium Web", sans-serif;
  }
  .row-social {
    padding-top: 10px;
    width: 150px;
  }
  .footer-logo {
    height: 130px;
    width: 130px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
    margin-left: 35%;
  }
  .footer-logo-img {
    height: 110px;
    width: 110px;
  }
  .footer-stepper {
    height: 130px;
    width: 130px;
    margin-top: 20%;
    margin-left: 35%;
  }
  .footer-stepper-img {
    height: 60px;
    width: 60px;
  }
  .footer-content {
    height: 100%;
    padding-top: 40px;
    padding-left: 10px;
  }
  .footer-social-img {
    height: 25px;
    width: 25px;
  }
  .footer-col-content h1 {
    font-size: 20px;
    font-weight: 600;
    font-family: "Titillium Web", sans-serif;
    color: black;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .footer-col-content ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
    padding-left: 0;
    font-family: "Titillium Web", sans-serif;
  }
  .footer-col-content li, .footer-col-content a {
    list-style: none;
    color: black;
    cursor: pointer;
    text-decoration: none;
  }
  .footer-col-content li:hover, .footer-col-content a:hover {
    text-decoration: underline;
  }
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .row-header {
    display: none;
  }
  /*
  #header {
      left: 0px;
      height: 2em;
  }

  .row-header {
      height: 2em;
      max-width: 480px;
      left: 0;
      background: #f2f2f2;
  } */
}
/* Media Query for low resolution  Tablets, Ipads */
/* @media (min-width: 481px) and (max-width: 767px) {
    body {
        background-color: yellow;
    }
} */
/* Media Query for Tablets Ipads portrait mode */
/* @media (min-width: 768px) and (max-width: 1024px){
    body {
        background-color: blue;
    }
} */
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  #header {
    left: 0px;
    height: 2.5em;
  }
  .row-header {
    position: absolute;
    height: 2.5em;
    width: 101%;
    left: 0;
    background: #f2f2f2;
  }
}
/* Media Query for Large screens */
/* @media (min-width: 1281px) {
    body {
        background-color: white;
    }
} */
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  #language {
    position: relative;
    top: 40%;
    padding-left: 0;
  }
  .language-img {
    padding-left: 0;
    padding-right: 0;
  }
  .language-img-flag {
    height: 25px;
    width: 25px;
    border-radius: 30%;
    display: block;
    position: relative;
    margin-right: 10px;
  }
  .language-list {
    padding-left: 0;
  }
  .language-item {
    font-size: small;
    text-decoration: unset !important;
    color: rgb(13, 110, 253);
    padding-right: 10px;
  }
  .language-item-selected {
    font-size: small;
    color: rgb(13, 110, 253);
    text-decoration: underline !important;
    padding-right: 10px;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  #language {
    position: relative;
    top: 15%;
    padding-left: 0;
  }
  .language-img {
    padding-left: 0;
    padding-right: 0;
  }
  .language-img-flag {
    height: 30px;
    width: 30px;
    border-radius: 30%;
    display: block;
    position: relative;
    margin-right: 10px;
  }
  .language-list {
    padding-left: 0;
  }
  .language-item {
    font-size: small;
    text-decoration: unset !important;
    color: rgb(13, 110, 253);
    padding-right: 10px;
  }
  .language-item-selected {
    font-size: small;
    color: rgb(13, 110, 253);
    text-decoration: underline !important;
    padding-right: 10px;
  }
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .logo-img {
    height: 100px;
    width: 100px;
  }
  .logo-link {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  .logo-img {
    height: 145px;
    width: 145px;
  }
  .logo-link {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  #menu {
    display: none;
  }
  #menu-responsive {
    height: auto;
  }
  .menu-contact-btn {
    background: #66696D;
    width: 120px;
    height: 40px;
    border: solid #66696D 2px;
    border-radius: 10px;
    font-family: "Titillium Web", sans-serif;
  }
  .menu-contact-btn a {
    text-decoration: none;
    color: inherit;
  }
  .menu-contact-btn:hover {
    background-color: white;
    color: #66696D;
  }
  .menu-contact-container {
    padding: 0;
  }
  .menu-contact-icon {
    margin-right: 5px;
  }
  .menu-icon {
    height: 45px;
    width: 45px;
    margin-top: 35%;
  }
  .menu-responsive-button-list {
    margin-top: 15%;
    margin-left: 45px;
    margin-right: 45px;
  }
  .menu-responsive-open {
    height: 50vh;
    transform: translateX(0);
  }
  .menu-responsive-close {
    height: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
  }
  .menu-title {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    font-family: "Titillium Web", sans-serif;
    font-weight: 900;
    font-size: 20px;
  }
  .menu-title-items ul {
    padding: 0;
  }
  .menu-title-items a {
    margin-left: 40px;
    text-decoration: none;
    text-decoration-color: rgb(25, 24, 24);
    color: rgb(25, 24, 24);
    font-size: 16px;
    font-weight: lighter;
    font-family: "Titillium Web", sans-serif;
  }
  .menu-title-items a:hover {
    text-decoration-color: #49B6CE;
    color: #49B6CE;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  #menu {
    height: 155px;
  }
  #menu-responsive {
    display: none;
  }
  .menu-item {
    text-align: center;
    line-height: 9;
    font-family: "Open Sans", sans-serif;
  }
  .menu-item-container {
    width: 75%;
    height: 155px;
    margin: auto;
  }
  .menu-item-link {
    text-decoration: none;
    text-decoration-color: rgb(25, 24, 24);
    color: rgb(25, 24, 24);
    font-size: 18px;
    font-weight: 600;
    font-family: "Titillium Web", sans-serif;
    width: auto;
    margin: auto;
  }
  .menu-item-link:hover {
    text-decoration-color: #49B6CE;
    color: #49B6CE;
  }
  .menu-subitem {
    position: relative;
    z-index: 2;
    background: white;
    height: 100px;
    margin: auto;
  }
  .menu-subitem-link {
    text-decoration: none;
    text-decoration-color: rgb(25, 24, 24);
    color: rgb(25, 24, 24);
    font-size: 18px;
    font-weight: 600;
    font-family: "Titillium Web", sans-serif;
    line-height: 100px;
    width: auto;
    margin: auto;
  }
  .menu-subitem-link:hover {
    text-decoration-color: #49B6CE;
    color: #49B6CE;
  }
  .menu-subitem:last-child {
    border-radius: 0 0 5px 5px;
  }
  .menu-divider {
    width: 10px;
  }
  .menu-button-list {
    margin-top: 60px;
    height: 36.5px !important;
  }
  .menu-button-contact {
    background-color: #AF332E;
    color: aliceblue;
  }
  .menu-contact {
    width: 300px;
  }
  .menu-contact-btn {
    background: #66696D;
    width: 120px;
    height: 40px;
    border: solid #66696D 2px;
    border-radius: 10px;
    font-family: "Titillium Web", sans-serif;
  }
  .menu-contact-btn a {
    text-decoration: none;
    color: inherit;
  }
  .menu-contact-btn:hover {
    background-color: white;
    color: #66696D;
  }
  .menu-contact-container {
    padding: 0;
  }
  .menu-contact-icon {
    margin-right: 5px;
  }
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .mission {
    height: 200px;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  .mission {
    height: 400px;
  }
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .section-mission {
    display: none;
  }
  .section {
    position: relative;
  }
  .section-responsive-mission {
    height: 250px;
    width: 100%;
    background: #66696D;
    opacity: 0.92;
  }
  .row-mission-title {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 900;
    font-size: 24px;
    color: white;
    font-family: "Titillium Web", sans-serif;
  }
  .row-mission-logo {
    background: white;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 10%;
    margin-top: 25px;
  }
  .row-mission-name {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-decoration-color: rgb(25, 24, 24);
    color: rgb(25, 24, 24);
    font-size: 16px;
    font-weight: 500;
    font-family: "Titillium Web", sans-serif;
  }
  #container-mission {
    height: 300px;
    padding: 0;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  .section-responsive-mission {
    display: none;
  }
  .section {
    position: relative;
  }
  .section-mission {
    height: 500px;
    width: 100%;
    background: #66696D;
    opacity: 0.92;
  }
  .row-mission-title {
    padding-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 900;
    font-size: 56px;
    color: white;
    font-family: "Titillium Web", sans-serif;
  }
  .row-mission-logo {
    background: white;
    border-radius: 50%;
    height: 130px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 31%;
    margin-top: 75px;
  }
  .row-mission-name {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-decoration-color: rgb(25, 24, 24);
    color: rgb(25, 24, 24);
    font-size: 24px;
    font-weight: 500;
    font-family: "Titillium Web", sans-serif;
  }
  #container-mission {
    /* border-left: black dashed;
    border-right: black dashed; */
    height: 700px;
  }
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  #programmes {
    display: none;
  }
  .section-responsive-programmes {
    height: 400px;
    background-color: white;
  }
  .block-programmes {
    background: whitesmoke;
    height: 300px;
    width: 350px;
    margin: auto;
    overflow: auto;
    position: absolute;
    left: 9%;
    top: 12%;
    border-radius: 4%;
    border: solid #49B6CE 2px;
    box-shadow: 0 0 2em #49B6CE;
  }
  .row-programmes {
    display: flex;
    justify-content: space-around;
    text-decoration: none;
    align-items: center;
    color: white;
    font-size: 8px;
    font-weight: bold;
    font-family: "Titillium Web", sans-serif;
    width: 300px;
    height: 30px;
    margin-top: 7px;
    margin-left: 23px;
    border-radius: 10px;
    background-color: #49B6CE;
    border: solid whitesmoke 2px;
  }
  .row-programmes:hover {
    border: solid #49B6CE 2px;
    background-color: whitesmoke;
    color: #49B6CE;
  }
  .row-programmes-title {
    padding-top: 15px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Titillium Web", sans-serif;
    font-size: 13px;
    font-weight: bold;
    color: #49B6CE;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  #programmes-responsive {
    display: none;
  }
  .section-programmes {
    height: 700px;
    background-color: white;
  }
  .block-programmes {
    /* background-color: #49B6CE; */
    /* background: linear-gradient(to bottom, rgba(73, 182, 206, 70%) 0%, rgba(73, 182, 206, 50%)); */
    background: whitesmoke;
    height: 550px;
    width: 900px;
    margin: auto;
    overflow: auto;
    position: absolute;
    left: 20%;
    top: 10%;
    border-radius: 3%;
    border: solid #49B6CE 2px;
    box-shadow: 0 0 2em #49B6CE;
  }
  .row-programmes {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: "Titillium Web", sans-serif;
    width: 750px;
    height: 45px;
    margin-top: 20px;
    margin-left: 60px;
    border-radius: 10px;
    /* background-color: linear-gradient(to bottom, rgba(73, 182, 206, 70%) 0%, rgba(73, 182, 206, 50%)); */
    background-color: #49B6CE;
    border: solid whitesmoke 2px;
  }
  .row-programmes:hover {
    border: solid #49B6CE 2px;
    background-color: whitesmoke;
    color: #49B6CE;
  }
  .row-programmes-title {
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Titillium Web", sans-serif;
    font-size: 26px;
    font-weight: bold;
    color: #49B6CE;
  }
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  #social-media {
    text-align: right;
    top: 1%; /* TODO */
    position: relative;
  }
  .social-media-logo {
    height: 20px;
    width: 20px;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  #social-media {
    text-align: right;
    top: 17%; /* TODO */
    position: relative;
  }
  .social-media-logo {
    height: 25px;
    width: 25px;
  }
  .social-media-row {
    width: 120px;
    margin-left: 20px;
  }
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .block-programmes-banner {
    background: black;
    opacity: 0.6;
    width: 100%;
    margin: auto;
    position: absolute;
    height: 100%;
    top: 0;
  }
  .block-programmes-title {
    color: white;
    font-family: "Titillium Web", sans-serif;
    font-size: 26px;
    font-weight: 600;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 10px;
    position: relative;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  .block-programmes-banner {
    background: black;
    opacity: 0.6;
    width: 75%;
    margin: auto;
    position: absolute;
    height: 100%;
    top: 0;
    left: 13%;
  }
  .block-programmes-title {
    color: white;
    font-family: "Titillium Web", sans-serif;
    font-size: 42px;
    font-weight: 600;
    justify-content: center;
    padding-bottom: 25px;
    padding-top: 25px;
    position: relative;
  }
  .block-programmes-item {
    color: white;
    font-family: "Titillium Web", sans-serif;
    font-size: 24px;
    font-weight: 500;
    justify-content: center;
    position: relative;
    padding-bottom: 25px;
  }
  .block-programmes-item a {
    font-size: 18px;
    justify-content: center;
  }
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .block-school-banner {
    background: black;
    opacity: 0.6;
    width: 100%;
    margin: auto;
    position: absolute;
    height: 100%;
    top: 0;
  }
  .block-school-title {
    color: white;
    font-family: "Titillium Web", sans-serif;
    font-size: 26px;
    font-weight: 600;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
  }
  .block-school-content {
    color: white;
    font-family: "Titillium Web", sans-serif;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 90%;
    margin: auto;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) {
  .block-school-banner {
    background: black;
    opacity: 0.6;
    width: 75%;
    margin: auto;
    position: absolute;
    height: 100%;
    top: 0;
    left: 13%;
  }
  .block-school-title {
    color: white;
    font-family: "Titillium Web", sans-serif;
    font-size: 42px;
    font-weight: 600;
    justify-content: center;
    padding-bottom: 25px;
    padding-top: 25px;
    position: relative;
  }
  .block-school-content {
    color: white;
    font-family: "Titillium Web", sans-serif;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 80%;
    margin: auto;
  }
}

